<template>
  <v-container fluid>
    <base-material-card
      color="primary"
      icon="mdi-database-check-outline"
      inline
      class="px-5 py-4 ml-0"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">Validações da BDGD</div>
      </template>

      <v-btn
        v-if="accessReleased('VALIDACOES_DA_BDGD_ADICIONAR')"
        id="btn-nova-validacao"
        color="success"
        dark
        elevation="1"
        class="mt-5"
        style="float: left"
        relative
        text
        medium
        @click="$router.push('add')"
      >
        <v-icon
          left
          size="20px"
        >
          mdi-plus-circle
        </v-icon>
        Nova Validação
      </v-btn>

      <v-btn
        v-if="accessReleased('VALIDACOES_DA_BDGD_STATUS_GERAL') && selectedBdgdVersion"
        color="success"
        dark
        elevation="1"
        class="ml-2 mt-5"
        style="float: left"
        relative
        text
        medium
        @click="goToStatusBdgd"
      >
        <v-icon
          left
          size="20px"
        >
          mdi-database-eye
        </v-icon>
        Status da BDGD
      </v-btn>

      <v-btn
        v-if="accessReleased('VALIDACOES_DA_BDGD_PAINEL_IQ_BDGD') && selectedBdgdVersion"
        color="success"
        dark
        elevation="1"
        class="ml-2 mt-5"
        style="float: left"
        relative
        text
        medium
        @click="goToPainelIqBdgd"
      >
        <v-icon
          left
          size="20px"
        >
          mdi-numeric-5-box-multiple-outline
        </v-icon>
        Painel IQ_BDGD
      </v-btn>

      <v-text-field
        id="validacoes-historico-search"
        v-model="search"
        append-icon="mdi-magnify"
        class="ml-auto mt-4 break-search"
        label="Procurar"
        hide-details
        single-line
        style="max-width: 250px"
      />

      <general-progress-bars
        class="mt-11"
        :items="downloadProgressData"
        prefixText="Baixando Validação"
      />

      <v-divider class="mt-10" />

      <v-data-table
        class="validacoes-bdgd-table"
        :headers="headers"
        :items="items"
        :search.sync="search"
        :mobile-breakpoint="800"
        :loading="loading"
        :hide-default-header="loading"
        :hide-default-footer="loading"
        no-data-text="Nenhuma validação de BDGD disponível"
      >
        <template v-slot:[`item.data_registro`]="{ item }">
          {{ item.data_registro | formatToMonth }} v{{ item.versao }}
        </template>
        <template v-slot:[`item.entidades`]="{ item }">
          <div
            id="validacoes-column-entity"
            class="clickable-item"
            @click="openDialogTabelas(item.entidades)"
          >
            {{ item.entidades | handleTabelasColumnValue }}
          </div>
        </template>
        <template v-slot:[`item.testes`]="{ item }">
          <div
            id="validacoes-column-entity"
            class="clickable-item"
            @click="openDialogTestes(item.testes)"
          >
            {{ item.testes | handleTestesColumnValue }}
          </div>
        </template>
        <template v-slot:[`item.numero_linhas`]="{ item }">
          <div id="validacoes-numero-linhas">
            {{ item.numero_linhas | parseNumberToIntegerBR }}
          </div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <general-status :status="item.status" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            :class="!podeBaixarZip(item.status) ? 'cursor-block' : ''"
            id="action-download-bdgd"
            class="px-1"
            min-width="0"
            fab
            icon
            x-small
            @click="baixarZip(item)"
            :loading="
              downloadProgressData[item.id] !== undefined ? true : false
            "
            :disabled="!podeBaixarZip(item.status)"
          >
            <v-icon small> mdi-download </v-icon>
          </v-btn>
          <v-btn
            id="validacoes-log-criacao"
            min-width="0"
            class="px-1"
            fab
            icon
            x-small
            @click="
              (dialogCreationLog = true),
                (dialogCreationLogData = {
                  username: item.usuario,
                  created: item.created
                })
            "
          >
            <v-icon medium> mdi-table-search </v-icon>
          </v-btn>
        </template>
        <template v-slot:[`footer.page-text`]>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="$emit('rechargeTable')"
                >mdi-refresh</v-icon
              >
            </template>
            <span>Clique aqui para recarregar as validações de BDGD</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
    <dialog-creation-log
      :dialog.sync="dialogCreationLog"
      :item="dialogCreationLogData"
    />
    <v-dialog
      v-model="dialogTabelas"
      scrollable
      max-width="500px"
    >
      <v-card>
        <v-card-title
          class="py-3"
          style="text-align: left !important"
        >
          <h2>Entidades selecionadas</h2>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-3">
          {{ getTabelasConcat }}
        </v-card-text>
        <v-divider />
        <v-card-actions
          style="text-align: right !important; display: block !important"
        >
          <v-btn
            color="green darken-1"
            @click="dialogTabelas = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogTestes"
      scrollable
      max-width="500px"
    >
      <v-card>
        <v-card-title
          class="py-3"
          style="text-align: left !important"
        >
          <h2>Testes selecionados</h2>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-3">
          <span v-html="getTestesConcat"></span>
        </v-card-text>
        <v-divider />
        <v-card-actions
          style="text-align: right !important; display: block !important"
        >
          <v-btn
            color="green darken-1"
            @click="dialogTestes = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import ValidacoesBdgdsService from '@/services/ValidacoesBdgdsService';
import powerupsActionsMixins from '@/mixins/powerupsActionsMixins.js';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';
import DialogCreationLog from '@/components/general/DialogCreationLog';
import GeneralStatus from '@/components/general/GeneralStatus';
import GeneralProgressBars from '@/components/general/GeneralProgressBars';

export default {
  mixins: [
    powerupsActionsMixins, 
    profilePermissionsMixin
  ],
  components: {
    DialogCreationLog,
    GeneralStatus,
    GeneralProgressBars
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    powerupService: ValidacoesBdgdsService,
    powerupEntityName: 'Validações da BDGD',
    dialogTabelas: false,
    dialogTabelasData: [],
    dialogTestes: false,
    dialogTestesData: [],
    search: '',
    headers: [
      {
        text: '#',
        value: 'id'
      },
      {
        text: 'Mês dos dados',
        value: 'data_registro'
      },
      {
        text: 'Entidades',
        value: 'entidades'
      },
      {
        text: 'Testes',
        value: 'testes'
      },
      {
        text: 'Nº de linhas',
        value: 'numero_linhas'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        sortable: false,
        text: 'Ações',
        value: 'actions',
        class: 'pl-4'
      }
    ]
  }),
  filters: {
    handleTabelasColumnValue(arrayTabelas) {
      let formattedValue = arrayTabelas[0].toUpperCase();
      let lengthArray = arrayTabelas.length;
      formattedValue =
        lengthArray > 1
          ? `${formattedValue} + ${lengthArray - 1}`
          : formattedValue;
      return formattedValue;
    },
    handleTestesColumnValue(arrayTestes) {
      let formattedValue = arrayTestes[0].teste;
      let lengthArray = arrayTestes.length;
      formattedValue =
        lengthArray > 1
          ? `${formattedValue} + ${lengthArray - 1}`
          : formattedValue;
      return formattedValue;
    }
  },
  methods: {
    goToStatusBdgd() {
      this.$router.push(`/geracao-bdgd/validacoes-bdgd/status-geral-bdgd/${this.selectedBdgdVersion.id}`);
    },
    goToPainelIqBdgd() {
      this.$router.push(`/geracao-bdgd/validacoes-bdgd/painel-iq-bdgd/${this.selectedBdgdVersion.id}`);
    },
    openDialogTabelas(arrNameTables) {
      this.dialogTabelasData = arrNameTables;
      this.dialogTabelas = true;
    },
    openDialogTestes(arrayTestes) {
      this.dialogTestesData = arrayTestes;
      this.dialogTestes = true;
    },
    baixarZip(validacao) {
      const { id, data_registro, versao } = validacao;
      const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
      const nomeArquivo = `validacao-bdgd-mes-${data_registro}-versao-${versao}-${timestamp}.zip`;

      this.$set(this.downloadProgressData, id, { id });
      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2000
      });

      ValidacoesBdgdsService.baixarZip(id, {
        timeout: 60 * 60 * 1000,
        onDownloadProgress: (progressEvent) => {
          let progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          let isComputable = progressEvent.lengthComputable;
          this.$set(this.downloadProgressData, id, {
            progress,
            isComputable,
            id
          });
        }
      })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nomeArquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao baixar o arquivo zip com a validação da BDGD.',
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        })
        .finally(() => {
          this.$delete(this.downloadProgressData, id);
        });
    },
    podeBaixarZip(status) {
      return !['AGUARDANDO', 'EXECUTANDO', 'EXPIRADO'].includes(status);
    }
  },
  computed: {
    selectedBdgdVersion() {
      return this.$store.getters.getUserSelectedCompanyBdgdVersion;
    },
    getTabelasConcat() {
      return this.dialogTabelasData.join(', ').toUpperCase();
    },
    getTestesConcat() {
      return this.dialogTestesData.map((teste) => teste.teste).join('<br>');
    }
  }
};
</script>

<style scoped>
.link {
  text-decoration: underline;
}
</style>
